import { logger } from "@/lib/logging/logger";
import { toast } from "@/components/ui/use-toast";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import { Move } from "@/types/Move";
import { formatDistanceToNowStrict } from "date-fns";
import locale from "date-fns/locale/en-US";
import { ApiLimit } from "@/app/(dashboard)/account/billing/components/BillingForm";
import { ApiLimit as PrismaApiLimit } from "@/prisma/schema/mysql";
import { ImagesInfo } from "@/types/Game";
import { ClientUser } from "@/types/ClientUser";
import Chess from "chess.js";
import { Evaluation, Turn } from "@/types/gameReviewTypes";
import {
  NextFetchEvent,
  NextMiddleware,
  NextRequest,
  NextResponse,
} from "next/server";
import { CDN_URL } from "./constants";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

// Pion (P) - Pawn (P)
// Tura (T) - Rook (R)
// Cal (C) - Knight (N)
// Nebun (N) - Bishop (B)
// Regina (Q) - Queen (Q)
// Rege (K) - King (K)

// R: Rege
// D: Damă
// T: Turn
// N: Nebun
// C: Cal

export const roToEnTranslationTable: { [key: string]: string } = {
  "": "", // pawn
  T: "R",
  C: "N",
  N: "B",
  D: "Q",
  R: "K",
};

export function moveFromEnglishToRomanian(chessMove: string): string {
  /*
  N -> C
  B -> N
  R -> T
  K -> R
  Q -> D
  */
  return chessMove
    .replace(/N/g, "C")
    .replace(/B/g, "N")
    .replace(/R/g, "T")
    .replace(/K/g, "R")
    .replace(/Q/g, "D");
}

export function moveFromRomanianToEnglish(chessMove: string): string {
  /*
  C -> N
  N -> B
  T -> R
  R -> K
  D -> Q
  */
  return chessMove
    .replace(/D/g, "Q")
    .replace(/R/g, "K")
    .replace(/N/g, "B")
    .replace(/C/g, "N")
    .replace(/T/g, "R");
}

// // Example usage:
// const move = "CNTDR";
// const translatedMove = parseMoveRoToEn(move);
// logger.info(translatedMove); // Output: NBRKQ

export function roundProbabilities(probabilities: number[]): number[] {
  return probabilities.map(
    (probability) => Math.round(probability * 100) / 100,
  );
}

export function roundNumber(number: number): number {
  return Math.round(number * 100) / 100;
}

// export function moveFromRomanianToEnglish(romanianMove: string): string {
//   if (romanianMove.length < 3) {
//     // pawn
//     return romanianMove;
//   }

//   const romanianPieces = romanianMove[0];
//   const englishPiece = roToEnTranslationTable[romanianPieces];
//   if (!englishPiece) {
//     return romanianMove;
//   }
//   const restOfMove = romanianMove.slice(1);

//   return englishPiece + restOfMove;
// }

export function getPieceSymbol(enMove: string): string {
  if (enMove === "O-O-O") {
    return "OOO";
  }
  if (enMove === "O-O") {
    return "OO";
  }
  if (enMove.length < 3) {
    // pawn
    return "P";
  }
  if (enMove[0].toLocaleLowerCase() === enMove[0] && enMove.length >= 3) {
    // cxd5
    return "P";
  }

  return enMove[0].toUpperCase();
}

export const enToRoTranslationTable: { [key: string]: string } = {
  "": "", // pawn
  R: "T",
  N: "C",
  B: "N",
  Q: "D",
  K: "R",
};

// export function moveFromEnglishToRomanian(englishMove: string): string {
//   if (englishMove.length < 3) {
//     // pawn
//     return englishMove;
//   }

//   const englishPieces = englishMove[0];
//   const romanianPiece = enToRoTranslationTable[englishPieces];
//   if (!romanianPiece) {
//     return englishMove;
//   }
//   const restOfMove = englishMove.slice(1);

//   return romanianPiece + restOfMove;
// }

export const copyToClipboard = (text: string) => {
  if (!text) {
    return;
  }
  navigator.clipboard.writeText(text);

  toast({
    description: "Text copied to clipboard",
    duration: 3000,
  });
};

// export const publicUploadRewrite = (privateUrl: string) => {
//   // "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/final_chess_moves.png"
//   // "/api/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/final_chess_moves.png"
//   const prefix = "/api"
//   return prefix + privateUrl;
// }

const formatDistanceLocale = {
  lessThanXSeconds: "just now",
  xSeconds: "just now",
  halfAMinute: "just now",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

function formatDistance(token: string, count: number, options?: any): string {
  options = options || {};

  const result = formatDistanceLocale[
    token as keyof typeof formatDistanceLocale
  ].replace("{{count}}", count.toString());

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result;
    } else {
      if (result === "just now") return result;
      return result + " ago";
    }
  }

  return result;
}

export function formatTimeToNow(date: Date): string {
  try {
    return formatDistanceToNowStrict(date, {
      addSuffix: true,
      locale: {
        ...locale,
        formatDistance,
      },
    });
  } catch (e) {
    logger.info({ e, date });
    return date.toDateString();
  }
}

export function getCredits(apiLimit?: ApiLimit | PrismaApiLimit) {
  if (!apiLimit) return 0;
  let credits = 0;
  if (apiLimit.planCredits) credits += apiLimit.planCredits;
  if (apiLimit.freeCredits) credits += apiLimit.freeCredits;
  if (apiLimit.otpCredits) credits += apiLimit.otpCredits;

  return credits;
}

export type ImageUrlConfig = {
  absolute?: boolean;
  local?: boolean;
  webp?: boolean;
};

export const imageUrl = (
  path: string,
  { absolute = false, local = false, webp = false }: ImageUrlConfig = {
    absolute: false,
    local: false,
    webp: false,
  },
) => {
  if (local) {
    return "/images/local/" + path;
  }
  if (!path) {
    return "";
  }
  // return REST_OCR_API + path;
  if (absolute) {
    return process.env.REST_OCR_API + path;
  }
  // chessocruploads/c30de190-7caa-4363-902f-62accf782096/cropped.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=TYK0oCIU0wKGEIsNHy30%2F20240214%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20240214T153333Z&X-Amz-Expires=7200&X-Amz-SignedHeaders=host&X-Amz-Signature=8dc57cdfd1f965b68027f7f34a503a2e7baf9383d2843c59b1a1b8c197e8d89d
  // "https://duhgfw2g1t6v3.cloudfront.net/chessocruploads/ffedf1cb-a789-4e23-a59f-7ef8c7b5be18/ffedf1cb-a789-4e23-a59f-7ef8c7b5be18.pdf"
  const isCloudFrontUrl = path.includes(CDN_URL);

  if (isCloudFrontUrl) {
    return path;
  }

  if (!isCloudFrontUrl && path.includes("chessocruploads")) {
    let result = path.slice(0, path.indexOf("?"));
    if (webp) return result.replace(".png", ".webp");
    return result;
  }
  return path;
};

export const isInMobileView = () => window.innerWidth <= 640;

export const mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

export function compareMoveswithChangedMoves(
  moves: Move[],
  changedMoves: Move[],
  windowSize: number,
  threshold: number,
): number[] {
  const result: number[] = [];

  for (let i = 0; i <= moves.length - windowSize; i++) {
    const movesWindow = moves.slice(i, i + windowSize);
    const changedWindow = changedMoves.slice(i, i + windowSize);
    const positiveCount = movesWindow.filter(
      (move: Move, idx: number) => move.move_en !== changedWindow[idx].move_en,
    ).length;
    result.push(positiveCount);
    if (positiveCount >= threshold) {
      return result;
    }
  }

  return result;
}

export function compareMoveswithPredChanged(
  moves: Move[],
  index: number,
  windowSize: number,
  threshold: number,
): number[] {
  const result: number[] = [];

  for (let i = index; i <= moves.length - windowSize; i++) {
    const movesWindow = moves.slice(i, i + windowSize);
    const positiveCount = movesWindow.filter(
      (move: Move) => move.move_ro !== move.predictions[0],
    ).length;
    result.push(positiveCount);
    if (positiveCount >= threshold) {
      // logger.info("here ", result, result.length);
      // debugger;
      return result;
    }
  }

  return result;
}

export function getMoveCell(move: string): string {
  if (move.length == 2) {
    return move;
  }
  for (let i = 0; i < move.length - 1; i++) {
    const line = move[i];
    const col = move[i + 1];
    const isLine =
      "a".charCodeAt(0) <= line.charCodeAt(0) &&
      line.charCodeAt(0) <= "h".charCodeAt(0);
    const isCol =
      "a".charCodeAt(0) <= line.charCodeAt(0) &&
      line.charCodeAt(0) <= "h".charCodeAt(0);

    if (isLine && isCol) {
      return `${line}${col}`;
    }
  }
  return "";
}

export type OmitTimestamps<T> = Omit<T, "createdAt" | "updatedAt">;

export const staleGame = (gameDate: Date, seconds: number): boolean => {
  if (!gameDate) return false;

  return new Date(gameDate).getTime() + seconds * 1000 < Date.now();
};

//nextauth needs to have / at the end for this to work
export function absoluteUrl(path: string) {
  if (typeof window !== "undefined") return path;
  if (process.env.NEXTAUTH_URL) return `${process.env.NEXTAUTH_URL}${path}`;
  return `http://localhost:${process.env.PORT ?? 3000}${path}`;
}

/**
 * Generates a unique UUID.
 *
 * @return {string} The generated UUID.
 */
export function generateUUID(): string {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: any) =>
      (
        parseInt(c) ^
        ((crypto.getRandomValues(new Uint8Array(1))[0] & 15) >>
          (parseInt(c) / 4))
      ).toString(16),
  );
}

export const getGameResultFromPgn = (pgn: string) => {
  if (pgn.includes("1-0")) return "white";
  if (pgn.includes("0-1")) return "black";
  if (pgn.includes("1/2")) return "draw";
  return "draw";
};

export function countPgnMoves(pgn: string): number {
  const moves = pgn.split(" ");
  let moveCount = 0;
  for (const move of moves) {
    if (!move.includes(".")) {
      moveCount++;
    }
  }
  return moveCount / 2;
}

export const formatShortenPgn = (pgn: string | undefined, toSplit: number) => {
  if (!pgn) return "No moves...";
  const [p1, p2] = pgn.split(toSplit + ".");
  if (!p2) return p1;
  return p1 + "... " + (pgn.split(".").length - toSplit) * 2 + " moves";
};

export const formatString = (str: string, num: number) => {
  if (str.length + 3 <= num) return str;
  return str.substring(0, num - 3) + "...";
};

type RecursiveObject = { [key: string]: any };

function replaceField<T extends RecursiveObject>(
  obj: T,
  from: string,
  to: string,
): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceField(item, from, to)) as unknown as T;
  }
  if (typeof obj === "object" && obj !== null) {
    const newObject: RecursiveObject = {};
    for (const [key, value] of Object.entries(obj)) {
      const newKey = key === from ? to : key;
      newObject[newKey] = replaceField(value, from, to);
    }
    return newObject as T;
  }
  return obj as T;
}

export const patchMongoSchema = (data: any[]): Move[] => {
  return data.map((move) => {
    const validKeys: (keyof Move)[] = [
      "bbox",
      "prediction",
      "predictions",
      "probabilities",
      "predictions_moves",
      "probabilities_moves",
      "strike_prob",
      "move_len",
      "player",
      "move_idx",
      "move_en",
      "move_ro",
      "fischer_score",
      "prediction_is_changed",
      "target_ids",
      "prediction_pos_from",
      "prediction_pos_to",
      "user_selected_move",
      "highlight",
      "ocr_value",
      "is_start_of_table",
      "comments",
      "piece",
      "line",
      "column",
      "capture",
      "castle",
      "two_cols",
      "src_col",
      "is_insertion",
    ];
    const fixedMove: Partial<Move> = {};
    for (const key of validKeys) {
      if (key in move) {
        fixedMove[key] = move[key];
      }
    }
    return {
      ...fixedMove,
      bbox: fixedMove.bbox || [],
      predictions: fixedMove.predictions || [],
      probabilities: fixedMove.probabilities || [],
      predictions_moves: fixedMove.predictions_moves || [],
      probabilities_moves: fixedMove.probabilities_moves || [],
      target_ids: fixedMove.target_ids || [],
    } as Move;
  });
};

export const toMongoDetections = (
  detections: any, // This is a workaround for prisma schema shortcomings
) =>
  replaceField(
    replaceField(
      replaceField(patchMongoSchema(detections), "_", "underscore"),
      "O-O",
      "short_castle",
    ),
    "O-O-O",
    "long_castle",
  );
export const toClientDetections = (detections: any) =>
  replaceField(
    replaceField(
      replaceField(detections, "underscore", "_"),
      "short_castle",
      "O-O",
    ),
    "long_castle",
    "O-O-O",
  );

export function deepEqual(
  obj1: RecursiveObject,
  obj2: RecursiveObject,
): boolean {
  if (obj1 === obj2) {
    return true;
  }
  if (
    obj1 == null ||
    obj2 == null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function getObjectDiff<T extends Record<string, any>>(
  obj1: T,
  obj2: T,
): Partial<T> {
  const diff: Partial<T> = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (!deepEqual(obj1[key], obj2[key])) {
        diff[key] = obj2[key];
      }
    }
  }

  return diff;
}

export const loadWorker = (url: string): Promise<Worker> => {
  return new Promise((resolve, reject) => {
    const worker = new Worker(url);

    worker.onmessage = (event) => {
      logger.info("WORKER EVENT: ", event);
      if (event.data === "worker-ready") {
        resolve(worker);
      }
    };
    worker.onerror = (error) => {
      reject(error);
    };
  });
};

export function getThubnailUrl(
  data: ImagesInfo,
  imgkind: string,
): string | null {
  if (imgkind == "cropped" && data.thumbnails?.cropped) {
    return data.thumbnails?.cropped;
  }
  if (imgkind == "original" && data.thumbnails?.original) {
    return data.thumbnails?.original;
  }
  return null;
}

export function getImageUrl(imgkind: string, data?: ImagesInfo): string | null {
  if (!data) return null;
  if (data.thumbnails) {
    return getThubnailUrl(data, imgkind);
  }
  if (imgkind == "cropped") {
    return data.images?.cropped;
  }
  return data.images?.original;
}

export function isAdminUser(user: ClientUser | null): boolean {
  return !!(user && user?.role === "admin");
}

/**
 * Splits an array into chunks of a specified size.
 * @param arr Array to be chunked
 * @param size Size of each chunk
 * @returns An array of chunks of the specified size
 */
export const chunk = (arr: any[], size: number): any[] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );

export function getPlayableMoves(moves: Move[]) {
  const game = new Chess();
  let idx = 0;
  while (idx < moves.length) {
    let move = moveFromRomanianToEnglish(moves[idx].user_selected_move);
    if (!game.move(move)) break;
    idx++;
  }
  return idx;
}

/**
 * Wraps a promise with a timeout.
 * @param promise The original promise to be wrapped.
 * @param timeoutMillis The timeout duration in milliseconds.
 * @returns A promise that will either resolve/reject with the original promise
 *          or reject with a timeout error.
 */
export function withTimeout<T>(
  promise: Promise<T>,
  timeoutMillis: number,
): Promise<T> {
  let timer: NodeJS.Timeout;

  // Create a promise that rejects after the timeout
  const timeoutPromise = new Promise<T>((_, reject) => {
    timer = setTimeout(() => {
      reject(new Error("Promise timed out"));
    }, timeoutMillis);
  });

  // Use Promise.race to race between the original promise and the timeout
  return Promise.race([promise, timeoutPromise]).finally(() => {
    clearTimeout(timer); // Clean up the timer if the original promise resolves or rejects
  });
}

export const getFormatedAdvantage = (
  turn: Turn,
  evaluation: Evaluation,
): string => {
  const povMate = evaluation.mate
    ? turn === "w"
      ? evaluation.mate
      : -evaluation.mate
    : undefined;
  const povEval =
    evaluation.cp !== null
      ? turn === "w"
        ? evaluation.cp
        : -evaluation.cp
      : null;

  if (povMate !== undefined) {
    return povMate > 0 ? `+M${povMate}` : `-M${Math.abs(povMate)}`;
  } else if (povEval !== null) {
    return povEval >= 0
      ? `+${(povEval / 100).toFixed(2)}`
      : `${(povEval / 100).toFixed(2)}`;
  } else {
    return "0.00";
  }
};

export const fischerScoreConfidence = (score: number) => {
  const low = -1,
    high = 25;
  if (!score) return 1;
  if (score >= high) return 0;
  if (score <= low) return 1;
  return 1 - (score - low) / (high - low);
};

export async function loadImage(url: string) {
  try {
    const response = await fetch(url, {
      headers: {
        cache: "no-cache",
      },
    });
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(objectUrl);
        resolve(img);
      };
      img.onerror = reject;
      img.src = objectUrl;
      // return img;
    });
  } catch (error) {
    throw new Error(`Failed to load image: ${(error as Error).message}`);
  }
}

export function groupArray<T>(arr: T[], groupSize: number) {
  let result = [];
  for (let i = 0; i < arr.length; i += groupSize) {
    result.push(arr.slice(i, i + groupSize));
  }
  return result;
}

export const createQueryString = (urlparams: Record<string, string>) => {
  const params = new URLSearchParams(urlparams);
  return params.toString();
};

export const capitalizeString = (input: string): string => {
  if (typeof input !== "string" || input.trim().length === 0) {
    return "";
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
};
